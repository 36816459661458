import React from "react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Box } from "@mui/material";

const Title = ({ entry, contentObject }) => {
  const titleText = (entry, contentObject) => {
    if (entry?.fields?.title) {
      return entry.fields.title;
    } else if (contentObject?.name) {
      return contentObject.name;
    } else {
      return "";
    }
  };
  if (titleText(entry, contentObject) === "") {
    return null;
  } else {
    return (
      <Box
        sx={{
          fontSize: "20px",
          paddingBottom: "15px",
        }}
      >
        {titleText(entry, contentObject)}
      </Box>
    );
  }
};

export const Description = ({ entry, contentObject }) => {
  if (
    !entry?.fields?.previewText &&
    !entry?.fields?.description &&
    !contentObject?.description
  ) {
    return null;
  } else {
    const entryDescription = (entry) => {
      if (entry?.fields?.previewText) {
        return entry.fields.previewText;
      } else if (entry?.fields?.description) {
        return entry.fields.description;
      } else {
        return null;
      }
    };

    return (
      <>
        {entryDescription(entry) ? (
          <div
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(entryDescription(entry)),
            }}
          />
        ) : (
          <Box>{contentObject ? contentObject.description : ""}</Box>
        )}
      </>
    );
  }
};

const BodyText = ({ entry, contentObject, hasPhoto }) => {
  const marginLeft = hasPhoto ? "24px" : "0px";

  return (
    <Box
      sx={{
        marginLeft: `${marginLeft}`,
      }}
    >
      <Title entry={entry} contentObject={contentObject} />
      <Description entry={entry} contentObject={contentObject} />
    </Box>
  );
};

const PhotoBox = ({ photoUrl, contentObject }) => {
  if (!photoUrl) {
    return null;
  }
  const altText = contentObject?.name ? contentObject.name : "";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* TODO: support videos */}
      <img
        src={photoUrl}
        width="200dp"
        style={{ borderRadius: "8px" }}
        alt={altText}
      ></img>
    </Box>
  );
};

export default function ContentCard({ entry, contentObject }) {
  const photoUrl = entry?.fields?.headerImage?.fields?.file?.url;
  const hasTitle = (entry, contentObject) => {
    if (entry?.fields?.title || contentObject?.name) {
      return true;
    } else {
      return false;
    }
  };
  const hasDescription = (entry, contentObject) => {
    if (
      entry?.fields?.previewText ||
      entry?.fields?.description ||
      contentObject?.description
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (
    !photoUrl &&
    !hasTitle(entry, contentObject) &&
    !hasDescription(entry, contentObject)
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "1px solid #f1f1f1",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 8px rgba(0, 0, 0, 0.06)",
        margin: "0px 0px 30px 0px",
        padding: "20px 30px",
        fontSize: "16px",
        background: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PhotoBox photoUrl={photoUrl} contentObject={contentObject} />
        <BodyText
          entry={entry}
          contentObject={contentObject}
          hasPhoto={!!photoUrl}
        />
      </Box>
    </Box>
  );
}
