import { useState, useEffect, useContext } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import { UserContext } from "../../contexts/user";
import LoadingButton from "@mui/lab/LoadingButton";
import capableAuthSDK from "@capable-health/capable-auth-sdk";
import * as Sentry from "@sentry/react";
import contentfulClient from "../../clients/contentfulClient";
import { Description } from "../ContentCard";

const defaultErrorMessages = {
  email: "",
  password: "",
};

const SignInForm = ({
  setUserConfirmed,
  setShowSignInForm,
  surveyId,
}: {
  setUserConfirmed: (confirmed: boolean) => void;
  setShowSignInForm: (show: boolean) => void;
  surveyId: string;
}) => {
  const { getUserContextFromLocalStorage, setUserContextInLocalStorage } = useContext(UserContext);
  const userContext = getUserContextFromLocalStorage(surveyId);
  const [formValues, setFormValues] = useState({
    email: userContext.email,
    password: "",
  });
  const [errorMessages, setErrorMessages] = useState(defaultErrorMessages);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpError, setSignUpError] = useState("");
  const [contentfulEntry, setContentfulEntry] = useState(null);

  useEffect(() => {
    if (process.env.REACT_APP_CONTENTFUL_SIGNUP_MESSAGE_ENTRY_ID) {
      contentfulClient
        .getEntry(process.env.REACT_APP_CONTENTFUL_SIGNUP_MESSAGE_ENTRY_ID)
        .then((entry) => {
          setContentfulEntry(entry);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrorMessages({
      ...errorMessages,
      [name]: "",
    });
  };

  const passwordIsValid = (password) => {
    const regex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])[\w^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]{8,}$/;

    return regex.test(password);
  };

  const hasAnAtSymbol = (email) => {
    const regex = /\S+@\S+/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = formValues;

    if (!passwordIsValid(password)) {
      setErrorMessages({
        ...errorMessages,
        password:
          "Password must be at least 8 characters long and contain at least one of each: Lowercase letter, uppercase letter, number, and special character",
      });
      return;
    }

    if (!hasAnAtSymbol(email)) {
      setErrorMessages({
        ...errorMessages,
        email: "Please enter a valid email!",
      });
      return;
    }

    try {
      setIsLoading(true);
      await capableAuthSDK.credentials.signIn(email, password);
      setUserConfirmed(true);
      setShowSignInForm(false);
      setUserContextInLocalStorage(surveyId, { ...userContext, ...{ email } });
      setIsLoading(false);
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      setSignUpError("Sorry, there was an error signing you in. Please try again.");
      setIsLoading(false);
      setUserContextInLocalStorage(surveyId, { ...userContext, ...{ email: "" } });
    }
  };

  const credentialFields = (
    <>
      <Grid item>
        <TextField
          required
          error={errorMessages.email !== ""}
          helperText={errorMessages.email}
          type="email"
          label="Email"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          required
          error={errorMessages.password !== ""}
          helperText={errorMessages.password}
          type="password"
          label="Password"
          name="password"
          value={formValues.password}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
    </>
  );

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 3,
          fontSize: "1.5rem",
          marginTop: "2rem",
          fontWeight: "medium",
        }}
      >
        Please sign in to continue
      </Box>
      {contentfulEntry && (
        <div style={{ textAlign: "center" }}>
          <Description entry={contentfulEntry} contentObject={undefined} />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={1}>
          {credentialFields}
          {signUpError !== "" && (
            <Grid item>
              <Box
                sx={{
                  color: "red",
                  fontSize: "1.5rem",
                  fontWeight: "medium",
                  textAlign: "center",
                  marginBottom: 1,
                }}
              >
                {signUpError}
              </Box>
            </Grid>
          )}
          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ margin: "1rem 0" }}
          >
            {isLoading ? (
              <LoadingButton loading color="primary" />
            ) : (
              <Button variant="contained" disableElevation type="submit">
                Sign In
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SignInForm;
