import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import contentfulClient from "../clients/contentfulClient";
import * as Sentry from "@sentry/react";
import { Description } from "../components/ContentCard";

const Welcome = () => {
  const [contentfulEntry, setContentfulEntry] = useState(null);
  const [isLoadingContentfulEntry, setIsLoadingContentfulEntry] = useState(true);

  useEffect(() => {
    if (
      process.env.REACT_APP_CONTENTFUL_WELCOME_MESSAGE_ENTRY_ID !== "" &&
      process.env.REACT_APP_CONTENTFUL_WELCOME_MESSAGE_ENTRY_ID !== undefined &&
      process.env.REACT_APP_CONTENTFUL_WELCOME_MESSAGE_ENTRY_ID !== null
    ) {
      contentfulClient
        .getEntry(process.env.REACT_APP_CONTENTFUL_WELCOME_MESSAGE_ENTRY_ID)
        .then((entry) => {
          setContentfulEntry(entry);
          setIsLoadingContentfulEntry(false);
        })
        .catch((err) => {
          Sentry.captureException(err);
          setIsLoadingContentfulEntry(false);
        });
    } else {
      setIsLoadingContentfulEntry(false);
    }
  }, []);

  if (isLoadingContentfulEntry) {
    return <Skeleton variant="rectangular" animation="wave" height={280} />;
  }

  if (contentfulEntry) {
    return <Description entry={contentfulEntry} contentObject={{}} />;
  }

  return (
    <>
      Welcome! Your answers to the following questions will help us understand where you are on your
      journey and your goals for the future. Then, we'll be able to create a personalized care plan
      just for you. Let's get started!
    </>
  );
};

export default Welcome;
