import React, { createContext } from "react";

import { LocalStorageNames } from "../constants/localStorage";

export interface User {
  email: string;
  phoneNumber: string;
  identityId: string;
  submissionId: string;
  submissionStatus: "completed" | null;
}

const getUserContextFromLocalStorage = (id: string): Partial<User> => {
  const stringifiedSubmission = localStorage.getItem(`${LocalStorageNames.userContext}-${id}`);
  return JSON.parse(stringifiedSubmission) || {};
};

const setUserContextInLocalStorage = (id: string, userContext: Partial<User>) => {
  const existingUserContext = getUserContextFromLocalStorage(id);
  localStorage.setItem(
    `${LocalStorageNames.userContext}-${id}`,
    JSON.stringify({ ...existingUserContext, ...userContext })
  );
};

const clearUserContextFromLocalStorage = (id: string) => {
  localStorage.removeItem(`${LocalStorageNames.userContext}-${id}`);
};

export const UserContext = createContext<{
  clearUserContextFromLocalStorage: (id: string) => void;
  getUserContextFromLocalStorage: (id: string) => Partial<User>;
  setUserContextInLocalStorage: (id: string, user: Partial<User>) => void;
}>({
  clearUserContextFromLocalStorage,
  getUserContextFromLocalStorage,
  setUserContextInLocalStorage,
});

export const UserProvider = ({ children }) => {
  return (
    <UserContext.Provider
      value={{
        clearUserContextFromLocalStorage,
        getUserContextFromLocalStorage,
        setUserContextInLocalStorage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
