import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ContentCard from "../ContentCard";
import contentfulClient from "../../clients/contentfulClient";
import * as Sentry from "@sentry/react";

export default function AfterActionsContent() {
  const [contentfulEntry, setContentfulEntry] = useState(null);

  useEffect(() => {
    if (process.env.REACT_APP_CONTENTFUL_AFTER_ACTIONS_ENTRY_ID !== "") {
      contentfulClient
        .getEntry(process.env.REACT_APP_CONTENTFUL_AFTER_ACTIONS_ENTRY_ID)
        .then((entry) => {
          setContentfulEntry(entry);
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    }
  }, []);

  return (
    <Box
      sx={{
        marginBottom: 3,
        marginTop: "2rem",
      }}
    >
        <ContentCard entry={contentfulEntry} contentObject={undefined} />
    </Box>
  );
}
