import React, { useContext, useState } from "react";

import IntakeResults from "../components/IntakeResults";
import AuthenticationForm from "../components/authentication/AuthenticationForm";
import StripePaymentElement from "../components/payments/StripePaymentElement";
import TrustCommercePaymentElement from "../components/payments/TrustCommercePaymentElement";
import Scheduling from "../components/Scheduling";
import ConfirmationContent from "../components/authentication/ConfirmationContent";
import AfterActionsContent from "../components/authentication/AfterActionsContent";

import { UserContext } from "../contexts/user";

export default function Intake({ surveyId }: { surveyId: string }) {
  const { getUserContextFromLocalStorage } = useContext(UserContext);

  const userContext = getUserContextFromLocalStorage(surveyId);

  const [resultsLoaded, setResultsLoaded] = useState(false);
  const [disqualified, setDisqualified] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [paymentDetailsCollected, setPaymentDetailsCollected] = useState(false);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState();
  const [schedulingLink, setSchedulingLink] = useState();
  const showRecommendationsOnTop = process.env.REACT_APP_SHOW_RECOMMENDATIONS_ON_TOP === "true";
  const showRecommendations = process.env.REACT_APP_HIDE_RECOMMENDATIONS !== "true";
  const usingStripePayment =
    process.env.REACT_APP_STRIPE_PUBLIC_KEY && process.env.REACT_APP_STRIPE_ACCOUNT_ID;
  const usingTrustCommercePayment =
    process.env.REACT_APP_TRUST_COMMERCE_CUSTOMER_ID &&
    process.env.REACT_APP_TRUST_COMMERCE_LICENSE_KEY;
  const usingPayments = usingStripePayment || usingTrustCommercePayment;
  const usingScheduling = !!process.env.REACT_APP_ACUITY_OWNER_ID;
  const showAfterActionsContent = process.env.REACT_APP_CONTENTFUL_AFTER_ACTIONS_ENTRY_ID;
  const displayAuthForm =
    (resultsLoaded || !showRecommendations) && !authenticated && !disqualified;
  const displayStripePaymentForm = authenticated && usingStripePayment && !paymentDetailsCollected;
  const displayTrustCommercePaymentForm =
    authenticated && usingTrustCommercePayment && !paymentDetailsCollected;
  const displayScheduling = () => {
    if (usingStripePayment || usingTrustCommercePayment) {
      return authenticated && paymentDetailsCollected && usingScheduling;
    } else {
      return authenticated && usingScheduling;
    }
  };

  return (
    <>
      {showRecommendations && showRecommendationsOnTop && (
        <IntakeResults
          setSubscriptionPlanId={setSubscriptionPlanId}
          setSchedulingLink={setSchedulingLink}
          submissionId={userContext.submissionId}
          setResultsLoaded={setResultsLoaded}
          setDisqualified={setDisqualified}
        />
      )}
      {displayAuthForm && (
        <AuthenticationForm
          submissionId={userContext.submissionId}
          setAuthenticated={setAuthenticated}
          userConfirmed={userConfirmed}
          setUserConfirmed={setUserConfirmed}
          surveyId={surveyId}
        />
      )}
      {userConfirmed && <ConfirmationContent />}
      {displayStripePaymentForm && (
        <StripePaymentElement
          setPaymentDetailsCollected={setPaymentDetailsCollected}
          subscriptionPlanId={subscriptionPlanId}
        />
      )}
      {displayTrustCommercePaymentForm && (
        <TrustCommercePaymentElement
          setPaymentDetailsCollected={setPaymentDetailsCollected}
          subscriptionPlanId={subscriptionPlanId}
        />
      )}
      {displayScheduling() && <Scheduling schedulingLink={schedulingLink} />}
      {showRecommendations && !showRecommendationsOnTop && (
        <IntakeResults
          setSubscriptionPlanId={setSubscriptionPlanId}
          setSchedulingLink={setSchedulingLink}
          submissionId={userContext.submissionId}
          setResultsLoaded={setResultsLoaded}
          setDisqualified={setDisqualified}
        />
      )}
      {(paymentDetailsCollected || (!usingPayments && userConfirmed)) &&
        showAfterActionsContent && <AfterActionsContent />}
    </>
  );
}
