import generic from "./generic";

export const genderOptions = {
  Female: "female",
  Male: "male",
  "Non-binary": "not_applicable",
  "Unknown": "unknown"
}

const Genders = generic(genderOptions, {
  label: "key",
});

export default Genders;