import React, { useState, useContext } from "react";
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import capableAuthSDK from "@capable-health/capable-auth-sdk";
import * as Sentry from "@sentry/react";

import { UserContext } from "../../contexts/user";

const usingPayment = !!process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const usingScheduling = !!process.env.REACT_APP_ACUITY_OWNER_ID;

export default function SignUpConfirmationForm({
  password,
  setShowSignUpForm,
  setShowConfirmationForm,
  setShowSignInForm,
  setUserConfirmed,
  cognitoUser,
  surveyId,
}) {
  const { getUserContextFromLocalStorage } = useContext(UserContext);
  const userContext = getUserContextFromLocalStorage(surveyId);
  const [emailState, setEmailState] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const missingEmail = process.env.REACT_APP_SIGNUP_MODE === "credentials" && !userContext.email;

  const handleConfirmationCodeChange = (e) => {
    const { value } = e.target;
    setConfirmationCode(value);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmailState(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      if (process.env.REACT_APP_SIGNUP_MODE === "credentials") {
        // if in credentials mode
        const email = userContext.email || emailState;
        await capableAuthSDK.credentials.confirmSignUp(email, confirmationCode);

        if (password) {
          // if password is present, complete sign-in process
          await capableAuthSDK.credentials.signIn(email, password);
          setUserConfirmed(true);
          setIsLoading(false);
          setShowConfirmationForm(false);
        } else if (usingPayment || usingScheduling) {
          // if password is not present but need to see another page, go to log-in screen
          setIsLoading(false);
          setShowConfirmationForm(false);
          setShowSignInForm(true);
        } else {
          // otherwise if password is not present, complete flow without logging in
          setIsLoading(false);
          setShowConfirmationForm(false);
        }
      } else {
        // if in passwordless mode
        await capableAuthSDK.passwordless.confirmSignIn(cognitoUser, confirmationCode);
        setIsLoading(false);
        setUserConfirmed(true);
        setShowConfirmationForm(false);
      }
    } catch (err) {
      Sentry.captureException(err);
      setErrorMessage("Sorry, there was an error confirming your account. Please try again.");
      setIsLoading(false);
      setShowConfirmationForm(true);
      setUserConfirmed(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 3,
          fontSize: "1rem",
          marginTop: "2rem",
        }}
      >
        {process.env.REACT_APP_SIGNUP_MODE === "credentials"
          ? userContext.email
            ? `Please enter the confirmation code sent to ${userContext.email}`
            : "Please enter your email and the confirmation code you received"
          : `Please enter the confirmation code sent to ${userContext.phoneNumber}`}
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={1} sx={{ justify: "center" }}>
          {missingEmail && (
            <Grid item>
              <TextField
                label="Email"
                name="email"
                value={emailState}
                onChange={handleEmailChange}
                required
                fullWidth
              />
            </Grid>
          )}
          <Grid item>
            <TextField
              label="Confirmation Code"
              name="confirmationCode"
              value={confirmationCode}
              onChange={handleConfirmationCodeChange}
              required
              fullWidth
            />
          </Grid>
          {errorMessage && (
            <Grid item>
              <Box
                sx={{
                  color: "red",
                  fontSize: "1rem",
                  marginBottom: 3,
                  textAlign: "center",
                }}
              >
                {errorMessage}
              </Box>
            </Grid>
          )}
          <Grid item display="flex" alignItems="center" justifyContent="center">
            {isLoading ? (
              <LoadingButton loading color="primary" />
            ) : (
              <Button variant="contained" disableElevation type="submit">
                Confirm
              </Button>
            )}
          </Grid>
          <Typography sx={{ alignSelf: "center" }}>
            Need to create an account?
            <Button
              onClick={() => {
                setShowConfirmationForm(false);
                setShowSignUpForm(true);
              }}
            >
              Sign Up
            </Button>
          </Typography>
        </Grid>
      </form>
    </>
  );
}
