import { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import capableAuthSDK from "@capable-health/capable-auth-sdk";
import * as Sentry from "@sentry/react";
import "../../styles/stripe.css";

const StripeErrorMessage = ({ message } : { message: string }) => (
  <Box sx={{ textAlign: "center", color: "#eb1c26", fontWeight: 500, padding: "1rem 0.5rem" }}>
    {message}
  </Box>
)

export default function StripePaymentElement({
  setPaymentDetailsCollected,
  subscriptionPlanId,
}) {
  const stripe = useStripe();
  const elements= useElements();
  const [currentPatient, setCurrentPatient] = useState(null);
  const [zipCode, setZipcode] = useState<string>();
  const [stripeError, setStripeError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Sentry.addBreadcrumb({message: "Getting current patient."});
    const getCurrentPatient = async () => {
      try {
        const accessToken = await capableAuthSDK.user.getAccessToken();
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_PATH}/patients/me`,
          requestOptions
        );
        const patient = await response.json();
        setCurrentPatient(patient);
      } catch(error) {
        Sentry.captureException(error);
        console.error("Unable to get patient");
      }
    };

    getCurrentPatient();
  }, []);

  const handleCollectPaymentMethod = async () => {
    try {
      const accessToken = await capableAuthSDK.user.getAccessToken();
      const requestOptions = subscriptionPlanId ? {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          subscription: {
            patient_id: currentPatient.id,
            price_ids: [subscriptionPlanId],
            // Assume stripe price id from recommendation are one time payment subscriptions.
            cancel_at_period_end: true,
          },
        }),
      } : {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          setup_intent: {
            patient_id: currentPatient.id,
          },
        }),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/${subscriptionPlanId ? "subscriptions" : "setup_intents"}`,
        requestOptions,
      );
      const intent = await response.json();

      const billingDetails = {
        address: {
          postal_code: zipCode,
        }
      };
      if (currentPatient?.email) {
        billingDetails["email"] = currentPatient.email;
      }
      if (currentPatient?.phone_number) {
        billingDetails["phone"] = currentPatient.phone_number.number;
      }

      if (subscriptionPlanId) {
        const { error } = await stripe.confirmCardPayment(
          intent.latest_invoice.payment_intent.client_secret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: billingDetails,
            },
          }
        )
        if (error) {
          setStripeError(error.message)
          setLoading(false);
        } else {
          setLoading(false);
          setPaymentDetailsCollected(true);
        }
      } else {
        const { error } = await stripe.confirmCardSetup(
          intent.client_secret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: billingDetails,
            },
          }
        );
        if (error) {
          setStripeError(error.message)
          setLoading(false);
        } else {
          setLoading(false);
          setPaymentDetailsCollected(true);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error("Unable to add payment method");
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setStripeError(null);

    try {
      await handleCollectPaymentMethod()
    } catch (error) {
      Sentry.captureException(error);
      console.error(error.message);
    }
  }

  return (
    <Box sx={{ background: "#FAFAFA", height: "100%" }}>
      <Box sx={{ padding: "1rem" }}>
        <StripeErrorMessage message={stripeError}/>
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography variant="h6">Card Number</Typography>
            <CardNumberElement />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ width: "45%"}}>
              <Typography variant="h6">Expiration</Typography>
              <CardExpiryElement />
            </Box>
            <Box sx={{ width: "45%"}}>
              <Typography variant="h6">CVC</Typography>
              <CardCvcElement />
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant="h6">Zip</Typography>
            </Box>
            <input
              className="StripeElement"
              name="zipCode"
              pattern="^\d{5}(-\d{4})?$"
              title="Five digit zip code"
              value={zipCode ?? ""}
              onChange={(e) => setZipcode(e.target.value)}
            />
          </Box>
          {loading ? (
            <LoadingButton loading sx={{ width: "100%", marginTop: "1rem" }} />
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={!stripe || !elements || !currentPatient || !zipCode || loading}
              sx={{ width: "100%", marginTop: "1rem" }}
            >
              Confirm
            </Button>
          )}
        </form>
      </Box>
    </Box>
  )
}