import React from 'react';
import ReactDOM from 'react-dom';

import './main.scss';
import App from './App';
import reportWebVitals from './config/reportWebVitals';
import configureErrorReporting from "./config/errorReporting";
import configureObservability from './config/observability';

(() => {
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
  // configure Sentry
  configureErrorReporting();
  // configure Datadog RUM
  configureObservability();

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

