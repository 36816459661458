interface CapableApiEnum {
  [key: string]: string;
}
interface CapableApiOption {
  label: string;
  value: string;
}
const CapableApiOptions = (
  capableApiEnum: CapableApiEnum,
  {
    textTransform = titlecase,
    label = "value",
  }: {
    textTransform?: (text: string) => string;
    label?: "key" | "value";
  } = {}
) => {
  return Object.entries(capableApiEnum).reduce<CapableApiOption[]>(
    (capableApiOptions, [capableApiKey, capableApiValue]) => {
      capableApiOptions.push({
        label: label === "key" ? textTransform(capableApiKey) : textTransform(capableApiValue),
        value: capableApiValue,
      });

      return capableApiOptions;
    },
    []
  );
};

export function titlecase(str) {
  return str
    .toLowerCase()
    .split(/[ _]/g)
    .map((w) => w.replace(w[0], w[0].toUpperCase()))
    .join(" ");
}


export default CapableApiOptions;
