import generic from "./generic";

const usaStateCodes = {
  alaska: "AK",
  alabama: "AL",
  arkansas: "AR",
  "american samoa": "AS",
  arizona: "AZ",
  california: "CA",
  colorado: "CO",
  connecticut: "CT",
  "district of columbia": "DC",
  delaware: "DE",
  florida: "FL",
  georgia: "GA",
  guam: "GU",
  hawaii: "HI",
  iowa: "IA",
  idaho: "ID",
  illinois: "IL",
  indiana: "IN",
  kansas: "KS",
  kentucky: "KY",
  louisiana: "LA",
  massachusetts: "MA",
  maryland: "MD",
  maine: "ME",
  michigan: "MI",
  minnesota: "MN",
  missouri: "MO",
  mississippi: "MS",
  montana: "MT",
  "north carolina": "NC",
  "north dakota": "ND",
  nebraska: "NE",
  "new hampshire": "NH",
  "new jersey": "NJ",
  "new mexico": "NM",
  nevada: "NV",
  "new york": "NY",
  ohio: "OH",
  oklahoma: "OK",
  oregon: "OR",
  pennsylvania: "PA",
  "puerto rico": "PR",
  "rhode island": "RI",
  "south carolina": "SC",
  "south dakota": "SD",
  tennessee: "TN",
  texas: "TX",
  utah: "UT",
  virginia: "VA",
  "virgin islands": "VI",
  vermont: "VT",
  washington: "WA",
  wisconsin: "WI",
  "west virginia": "WV",
  wyoming: "WY",
};

const USAStateCodes = generic(usaStateCodes, {
  label: "key",
});

export default USAStateCodes;
