import React, { useState, useEffect } from "react";
import SignUpForm from "./SignUpForm";
import SignUpConfirmationForm from "./SignUpConfirmationForm";
import capableAuthSDK from "@capable-health/capable-auth-sdk";
import * as Sentry from "@sentry/react";
import SignInForm from "./SignInForm";

const AuthenticationForm = ({
  submissionId,
  setAuthenticated,
  userConfirmed,
  setUserConfirmed,
  surveyId,
}) => {
  const [password, setPassword] = useState(null);
  const [showSignUpForm, setShowSignUpForm] = useState(!!process.env.REACT_APP_SIGNUP_MODE);
  const [showConfirmationForm, setShowConfirmationForm] = useState(false);
  const [showSignInForm, setShowSignInForm] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  useEffect(() => {
    const linkSubmissionToPatient = async () => {
      try {
        const patient = await capableAuthSDK.user.getPayload();
        const submissionObject = {
          submission: {
            id: submissionId,
            patient_id: patient.userId,
          },
        };
        const accessToken = await capableAuthSDK.user.getAccessToken();
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(submissionObject),
        };
        await fetch(`${process.env.REACT_APP_API_PATH}/surveys/submissions/link`, requestOptions);
      } catch (err) {
        Sentry.captureException(err);
        console.log("Unable to link Submission to Patient");
      }
    };

    if (userConfirmed) {
      linkSubmissionToPatient();
      setAuthenticated(true);
    }
  }, [userConfirmed, submissionId, setAuthenticated]);

  return (
    <>
      {showSignUpForm && (
        <SignUpForm
          setPassword={setPassword}
          setShowConfirmationForm={setShowConfirmationForm}
          setShowSignUpForm={setShowSignUpForm}
          setCognitoUser={setCognitoUser}
          surveyId={surveyId}
        />
      )}
      {showConfirmationForm && (
        <SignUpConfirmationForm
          password={password}
          setShowSignUpForm={setShowSignUpForm}
          setShowConfirmationForm={setShowConfirmationForm}
          setShowSignInForm={setShowSignInForm}
          setUserConfirmed={setUserConfirmed}
          cognitoUser={cognitoUser}
          surveyId={surveyId}
        />
      )}
      {showSignInForm && (
        <SignInForm
          setUserConfirmed={setUserConfirmed}
          setShowSignInForm={setShowSignInForm}
          surveyId={surveyId}
        />
      )}
    </>
  );
};

export default AuthenticationForm;
