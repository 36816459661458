import { Box } from "@mui/material";
import { Link } from "@mui/material";
import capableLogo from "../assets/logo.svg";

export default function Header() {
  const backgroundStyle = {
    backgroundColor: "primary.main",
    paddingTop: 5, // Zeke can't tell how this is calculated, will revisit
    paddingBottom: 5,
    textAlign: "center",
    marginBottom: 3,
    "@media (max-width: 600px)": {
      paddingTop: 3,
      paddingBottom: 3,
    },
  };

  const linkStyle = {
    display: "block",
  };

  const imageStyle = {
    display: "block",
    margin: "0 auto",
    width: 180,
    "@media (max-width: 600px)": {
      maxWidth: 100,
    },
  };

  const imgSrc =
    process.env.REACT_APP_LOGO === ""
      ? capableLogo
      : process.env.REACT_APP_LOGO;

  return process.env.REACT_APP_HIDE_HEADER_BANNER === 'true' ? null : (
    <Box sx={backgroundStyle}>
      <Link sx={linkStyle} href="/">
        <Box component="img" sx={imageStyle} alt="Company logo" src={imgSrc} />
      </Link>
    </Box>
  );
}
