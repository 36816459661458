import { datadogRum } from "@datadog/browser-rum";

// Function to configure Datadog RUM for observability.
// NOTE: This sample app uses Datadog RUM for observability. If you
//       have forked this repo you will need to setup Datadog RUM for
//       your own project, or use a different observability service.
//       Docs: https://docs.datadoghq.com/real_user_monitoring/
export default function configureObservability() {
  const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
  if (applicationId && clientToken) {
    try {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID as string,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
        site: "datadoghq.com",
        service: "questionnaire",
        env: process.env.REACT_APP_OBSERVABILITY_ENV || "local",
        version: process.env.npm_package_version,
        sampleRate: 100,
        sessionReplaySampleRate: Number(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLING || 0),
        trackInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingOrigins: [/https:\/\/.*\.capablehealth\.com/],
      });

      if (Number(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLING || 0) > 0) {
        datadogRum.startSessionReplayRecording();
      }
    } catch (error) {
      console.error(error);
    }
  }
}
