import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import { UserContext } from "../../contexts/user";
import LoadingButton from "@mui/lab/LoadingButton";
import capableAuthSDK from "@capable-health/capable-auth-sdk";
import { validatePhoneNumber } from "@capable-health/capable-auth-sdk/dist/esm/helpers/validation";
import * as Sentry from "@sentry/react";
import contentfulClient from "../../clients/contentfulClient";
import { Description } from "../ContentCard";
import USAStateCodes from "../../constants/usaStateCodes";
import Genders from "../../constants/administrativeGenders";
import { format } from "date-fns";

const defaultErrorMessages = {
  email: "",
  password: "",
  phone_number: "",
  administrative_gender: "",
  birth_date: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  zip_code: "",
};

const TermOfServiceField = ({ setAcceptedTerms }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <Checkbox
        checked={isChecked}
        onChange={() => {
          setIsChecked(!isChecked);
          setAcceptedTerms(!isChecked);
        }}
      />
      <span style={{ paddingTop: "9px" }}>
        By signing up you agree to our{" "}
        <a href={process.env.REACT_APP_TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        .
      </span>
    </div>
  );
};

export default function SignUpForm({
  setPassword,
  setShowConfirmationForm,
  setShowSignUpForm,
  setCognitoUser,
  surveyId,
}) {
  const { getUserContextFromLocalStorage, setUserContextInLocalStorage } = useContext(UserContext);
  const userContext = getUserContextFromLocalStorage(surveyId);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    birth_date: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zip_code: "",
    administrative_gender: "",
    email: userContext.email,
    password: "",
    phone_number: userContext.phoneNumber,
  });
  const [errorMessages, setErrorMessages] = useState(defaultErrorMessages);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpError, setSignUpError] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(!process.env.REACT_APP_TERMS_OF_SERVICE_URL);
  const [acceptedTextMessage, setAcceptedTextMessage] = useState(
    process.env.REACT_APP_SIGNUP_MODE === "credentials"
  );
  const [contentfulEntry, setContentfulEntry] = useState(null);

  useEffect(() => {
    if (process.env.REACT_APP_CONTENTFUL_SIGNUP_MESSAGE_ENTRY_ID) {
      contentfulClient
        .getEntry(process.env.REACT_APP_CONTENTFUL_SIGNUP_MESSAGE_ENTRY_ID)
        .then((entry) => {
          setContentfulEntry(entry);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrorMessages({
      ...errorMessages,
      [name]: "",
    });
  };

  const passwordIsValid = (password) => {
    const regex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])[\w^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]{8,}$/;

    return regex.test(password);
  };

  const hasAnAtSymbol = (email) => {
    const regex = /\S+@\S+/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      email,
      firstName,
      lastName,
      password,
      phone_number,
      birth_date,
      line1,
      line2,
      city,
      state,
      zip_code,
      administrative_gender,
    } = formValues;
    const phoneNumber = `+1${phone_number}`;

    if (process.env.REACT_APP_SIGNUP_MODE === "credentials") {
      if (!passwordIsValid(password)) {
        setErrorMessages({
          ...errorMessages,
          password:
            "Password must be at least 8 characters long and contain at least one of each: Lowercase letter, uppercase letter, number, and special character",
        });
        return;
      }

      if (!hasAnAtSymbol(email)) {
        setErrorMessages({
          ...errorMessages,
          email: "Please enter a valid email!",
        });
        return;
      }
    } else {
      try {
        validatePhoneNumber(phoneNumber);
      } catch (error) {
        setErrorMessages({
          ...errorMessages,
          phone_number: error.message,
        });
        return;
      }
    }

    if (process.env.REACT_APP_REQUIRE_ADDITIONAL_PATIENT_DETAIL_ON_SIGNUP === "true") {
      if (!/^\d{5}(-\d{4})?$/.test(zip_code)) {
        setErrorMessages({
          ...errorMessages,
          zip_code: "Zip code is not valid",
        });
        return;
      }
    }

    try {
      const attributes = {
        first_name: firstName,
        last_name: lastName,
        metadata: process.env.REACT_APP_TERMS_OF_SERVICE_URL
          ? { accepted_terms_of_service: true }
          : {},
      };

      if (userContext.identityId) {
        attributes["identity_id"] = userContext.identityId;
      }

      if (process.env.REACT_APP_REQUIRE_ADDITIONAL_PATIENT_DETAIL_ON_SIGNUP === "true") {
        attributes["birth_date"] = birth_date;
        attributes["administrative_gender"] = administrative_gender;
        attributes["addresses_attributes"] = [
          {
            line1: line1,
            line2: line2,
            city: city,
            state: state,
            zip: zip_code,
            country: "USA",
            primary: true,
          },
        ];
      }

      setIsLoading(true);

      attributes["identity_id"] = userContext.identityId;

      if (process.env.REACT_APP_SIGNUP_MODE === "credentials") {
        if (!!phone_number) {
          attributes["phones_attributes"] = [
            {
              number: phoneNumber,
              primary: true,
              active: true,
            },
          ];
        }
        await capableAuthSDK.credentials.signUp(email, password, attributes);
      } else {
        if (!!email) {
          attributes["email"] = email;
        }
        await capableAuthSDK.passwordless.signUp(phoneNumber, attributes);
        const cognitoUser = await capableAuthSDK.passwordless.signIn(phoneNumber);
        setCognitoUser(cognitoUser);
      }

      userContext.email = email;
      userContext.phoneNumber = phoneNumber;
      setUserContextInLocalStorage(surveyId, userContext);
      setPassword(password);
      setShowSignUpForm(false);
      setShowConfirmationForm(true);
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
      setSignUpError("Sorry, there was an error signing you up. Please try again.");
      setIsLoading(false);

      userContext.email = "";
      userContext.phoneNumber = "";
      setUserContextInLocalStorage(surveyId, userContext);
      setPassword("");
      setShowSignUpForm(true);
      setShowConfirmationForm(false);
    }
  };

  const credentialFields = (
    <>
      <Grid item>
        <TextField
          required
          error={errorMessages.email !== ""}
          helperText={errorMessages.email}
          type="email"
          label="Email"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          required
          error={errorMessages.password !== ""}
          helperText={errorMessages.password}
          type="password"
          label="Password"
          name="password"
          value={formValues.password}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
    </>
  );

  const PasswordlessField = () => {
    const [isChecked, setIsChecked] = useState(false);

    return (
      <>
        <Grid item>
          <TextField
            required
            error={errorMessages.phone_number !== ""}
            helperText={errorMessages.phone_number}
            type="phone_number"
            label="Phone Number"
            name="phone_number"
            value={formValues.phone_number}
            onChange={handleInputChange}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">+1</InputAdornment>,
            }}
          />
        </Grid>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Checkbox
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
              setAcceptedTextMessage(!isChecked);
            }}
          />
          <span style={{ paddingTop: "9px" }}>
            {`By submitting your phone number, you consent to receive a one-time login code from ${process.env.REACT_APP_NAME} at the number provided. Message and data rates may apply.`}
          </span>
        </div>
      </>
    );
  };

  const AdditionalSignupFields = () => {
    return (
      <>
        <Grid item>
          <TextField
            required
            select
            error={errorMessages.administrative_gender !== ""}
            helperText={errorMessages.administrative_gender}
            label="Gender"
            name="administrative_gender"
            value={formValues.administrative_gender}
            onChange={handleInputChange}
            fullWidth
          >
            {Genders.map((genderOption) => (
              <MenuItem key={genderOption.value} value={genderOption.value}>
                {genderOption.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            required
            error={errorMessages.birth_date !== ""}
            helperText={errorMessages.birth_date}
            type="date"
            label="Date of Birth"
            name="birth_date"
            inputProps={{
              max: format(new Date(), "yyyy-MM-dd"),
            }}
            value={formValues.birth_date}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={errorMessages.line1 !== ""}
            helperText={errorMessages.line1}
            type="text"
            label="Address line 1"
            name="line1"
            value={formValues.line1}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            error={errorMessages.line2 !== ""}
            helperText={errorMessages.line2}
            type="text"
            label="Address line 2"
            name="line2"
            value={formValues.line2}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={errorMessages.city !== ""}
            helperText={errorMessages.city}
            type="text"
            label="City"
            name="city"
            value={formValues.city}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            required
            select
            error={errorMessages.state !== ""}
            helperText={errorMessages.state}
            label="State"
            name="state"
            value={formValues.state}
            onChange={handleInputChange}
            fullWidth
          >
            {USAStateCodes.map((stateOption) => (
              <MenuItem key={stateOption.value} value={stateOption.value}>
                {stateOption.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            required
            error={errorMessages.zip_code !== ""}
            helperText={errorMessages.zip_code}
            type="text"
            label="Zip"
            name="zip_code"
            value={formValues.zip_code}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 3,
          fontSize: "1.5rem",
          marginTop: "2rem",
          fontWeight: "medium",
        }}
      >
        Sign up for an account
      </Box>
      {contentfulEntry && (
        <div style={{ textAlign: "center" }}>
          <Description entry={contentfulEntry} contentObject={undefined} />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <TextField
              required
              type="text"
              label="First Name"
              name="firstName"
              value={formValues.firstName}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              required
              type="text"
              label="Last Name"
              name="lastName"
              value={formValues.lastName}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          {process.env.REACT_APP_REQUIRE_ADDITIONAL_PATIENT_DETAIL_ON_SIGNUP === "true" &&
            AdditionalSignupFields()}
          {process.env.REACT_APP_SIGNUP_MODE === "credentials"
            ? credentialFields
            : PasswordlessField()}
          {process.env.REACT_APP_TERMS_OF_SERVICE_URL && (
            <TermOfServiceField setAcceptedTerms={setAcceptedTerms} />
          )}
          {signUpError !== "" && (
            <Grid item>
              <Box
                sx={{
                  color: "red",
                  fontSize: "1.5rem",
                  fontWeight: "medium",
                  textAlign: "center",
                  marginBottom: 1,
                }}
              >
                {signUpError}
              </Box>
            </Grid>
          )}
          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ margin: "1rem 0" }}
          >
            {isLoading ? (
              <LoadingButton loading color="primary" />
            ) : (
              <Button
                disabled={!acceptedTerms || !acceptedTextMessage}
                variant="contained"
                disableElevation
                type="submit"
              >
                Sign Up
              </Button>
            )}
          </Grid>
          {process.env.REACT_APP_SIGNUP_MODE === "credentials" && (
            <Typography sx={{ alignSelf: "center" }}>
              Already signed up?
              <Button
                onClick={() => {
                  setShowConfirmationForm(true);
                  setShowSignUpForm(false);
                }}
              >
                Confirm your account
              </Button>
            </Typography>
          )}
        </Grid>
      </form>
    </>
  );
}
