import capableAuthSDK from "@capable-health/capable-auth-sdk";
import { Box, Skeleton, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

const useCreatePatientBilling = (subscriptionPlanId: string) => {
  const [patientBilling, setPatientBilling] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const postPatientBilling = async () => {
      const accessToken = await capableAuthSDK.user.getAccessToken();

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          {
            patient_billing: {
              subscription_plan_id: subscriptionPlanId,
            },
          },
        ),
      };
  
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/trust_commerce/patient_billings`,
        requestOptions,
      );

      const patientBilling = await response.json();

      if (patientBilling.errors) {
        Sentry.captureMessage(
          patientBilling.errors[0].title ||
            "Unable to fetch the suggestions for submission",
          {
            level: "error",
          }
        );

        return;
      }

      setPatientBilling(patientBilling);
      setIsLoading(false);
    };

    postPatientBilling();
  }, [subscriptionPlanId]);

  return { data: patientBilling, isLoading };
}

export default function TrustCommercePaymentElement({
  setPaymentDetailsCollected,
  subscriptionPlanId,
}) {
  const {
    data: patientBilling,
    isLoading: isCreatingPatientBilling,
  } = useCreatePatientBilling(subscriptionPlanId);

  const [paymentLifecycleCount, setPaymentLifecycleCount] = useState(0);
  const paymentLifecycle = ["mountingPaymentHostedPage", "waitingOnPayment", "paymentIsProcessed"];

  useEffect(() => {
    if (paymentLifecycle[paymentLifecycleCount] === "paymentIsProcessed") {
      setPaymentDetailsCollected(true);
    }
  }, [paymentLifecycleCount]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isCreatingPatientBilling || patientBilling === undefined) {
    return <Skeleton variant="rectangular" animation="wave" height={280} />;
  }

  const trustCommerceHostedPaymentUrl = `https://stagepremier.trustcommerce.com/trustcommerce_payment/index.php?aggregators=27NM1P5&trxcustid=${process.env.REACT_APP_TRUST_COMMERCE_CUSTOMER_ID}&trxcustid_licensekey=${process.env.REACT_APP_TRUST_COMMERCE_LICENSE_KEY}&storeonly=y&save_btn_text=Pay&response_url=https://api.dev.capablehealth.com/webhooks/trust_commerce&trxcustomfield[1]=${patientBilling?.secret}&customfield[1]=${patientBilling?.secret}`;

  return (
    <Box
      sx={{
        height: "500px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      {paymentLifecycle[paymentLifecycleCount] === "paymentIsProcessed" ? (
       <>
          <Typography variant="h2">Payment Successful</Typography>
          <Typography variant="h6" sx={{ padding: "1rem 0 2rem" }}>
            <p>Your payment has been processed.</p>
          </Typography>
        </>
      ) : (
        <iframe
          src={trustCommerceHostedPaymentUrl}
          title="Subscription Payment"
          width="100%"
          height="100%"
          frameBorder="0"
          onLoad={() => setPaymentLifecycleCount(paymentLifecycleCount + 1)}
        />
      )}
    </Box>
  );
};