import { createTheme } from "@mui/material/styles";

const black = "#020228";

// Using most of the same defaults as in the Capable Care app for now
export const theme = createTheme({
  palette: {
    background: {
      default: "#fafafa",
    },
    primary: {
      main: process.env.REACT_APP_COLOR,
    },
    secondary: {
      main: "#333333",
    },
    light: {
      main: "#ffffff",
    },
    text: {
      primary: black,
    },
    icon: {
      primary: process.env.REACT_APP_COLOR,
    },
    link: {
      default: process.env.REACT_APP_COLOR,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: "Rubik",
    h1: {
      fontSize: "2.5rem",
      color: black,
    },
    h2: {
      fontSize: "2rem",
      color: black,
    },
    h3: {
      fontSize: "1.75rem",
      color: black,
    },
    h4: {
      fontSize: "1.5rem",
      fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
      color: black,
    },
    h5: {
      fontSize: "1.25rem",
      color: black,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 400,
      color: black,
    },
    h7: {
      fontSize: "0.9rem",
      fontWeight: 400,
      color: black,
    },
    headline: {
      color: black,
      fontSize: "1.25rem",
      lineHeight: "2rem",
      letterSpacing: -0.25,
      fontWeight: 500,
    },
    subtitle: {
      fontWeight: 400,
      fontSize: "1rem",
      marginTop: "1rem",
      marginBottom: "1rem",
      color: black,
    },
  },
});

export default theme;
