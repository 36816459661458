import { CapableClient } from "@capable-health/client";
import { createContext } from "react";

const BASE_API_PATH: string =
  process.env.REACT_APP_API_PATH || "https://api.dev.capablehealth.com";

const capableClient = new CapableClient({
  BASE: BASE_API_PATH,
});

export const CapableClientContext = createContext<CapableClient>(capableClient);

const CapableClientProvider = ({ children }): JSX.Element => {
  return (
    <CapableClientContext.Provider value={capableClient}>{children}</CapableClientContext.Provider>
  );
};

export default CapableClientProvider;
