import capableAuthSDK from "@capable-health/capable-auth-sdk";
import { ThemeProvider } from "@mui/material/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// components
import Survey from "./components/Survey";

// contexts
import CapableClientProvider from "./contexts/capableClient";
import { UserProvider } from "./contexts/user";

// styles
import theme from "./styles/theme";
import CssBaseline from "@mui/material/CssBaseline";
import "survey-react/survey.css";
import "@capable-health/ui/dist/index.css";


if (!!process.env.REACT_APP_SIGNUP_MODE) {
  capableAuthSDK[process.env.REACT_APP_SIGNUP_MODE].configure(
    process.env.REACT_APP_CAPABLE_AUTH_CONFIGURATION_TOKEN
  );
}

let stripePromise;
if (!!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT_ID,
  });
}

const queryClient = new QueryClient();
function App() {
  const RouterWithPaths = () => (
    <Router>
      <CssBaseline />
      <Switch>
        <Route path="/:id" children={<Survey />} />
        <Route
          path="/"
          children={<Survey id={process.env.REACT_APP_SURVEY_ID} />}
        />
      </Switch>
    </Router>
  );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <CapableClientProvider>
              {!!process.env.REACT_APP_STRIPE_PUBLIC_KEY ? (
                <Elements stripe={stripePromise}>
                  <RouterWithPaths />
                </Elements>
              ) : (
                <RouterWithPaths />
              )}
            </CapableClientProvider>
          </QueryClientProvider>
        </UserProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
