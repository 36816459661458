import capableAuthSDK from "@capable-health/capable-auth-sdk";
import { Survey as SurveyComponent } from "@capable-health/ui";
import { useParams } from "react-router-dom";
import React, { useContext } from "react";

import { CapableClientContext } from "../contexts/capableClient";
import { User, UserContext } from "../contexts/user";
import Header from "./Header";
import Intake from "../pages/Intake";
import Welcome from "../pages/Welcome";

const Survey = (props) => {
  const capableClient = useContext(CapableClientContext);
  const {
    clearUserContextFromLocalStorage,
    getUserContextFromLocalStorage,
    setUserContextInLocalStorage,
  } = useContext(UserContext);
  const { id } = useParams();

  const surveyId = id || props.id;

  const onSubmit = (survey, results, submission) => {
    const user: Partial<User> = {
      submissionId: submission.id,
      identityId: submission.identity_id,
    };

    const emailQuestion = survey.questions.find((question) => {
      return question.type === "EmailQuestion";
    });

    if (emailQuestion) {
      const emailResult = submission.responses.find((response) => {
        return response.question_id === emailQuestion.id;
      });

      if (emailResult) {
        user.email = emailResult.content;
      }
    }

    const phoneNumberQuestion = survey.questions.find((question) => {
      return question.type === "PhoneNumberQuestion";
    });

    if (phoneNumberQuestion) {
      const phoneNumberResult = submission.responses.find((response) => {
        return response.question_id === phoneNumberQuestion.id;
      });

      if (phoneNumberResult) {
        user.phoneNumber = phoneNumberResult.content;
      }
    }

    setUserContextInLocalStorage(surveyId, user);
  };

  return (
    <>
      <Header />
      <div className="wrap">
        <SurveyComponent
          capableClient={capableClient}
          completedComponent={<Intake surveyId={surveyId} />}
          id={surveyId}
          introductionComponent={<Welcome />}
          onSubmit={onSubmit}
          onComplete={async () => {
            const userContext = getUserContextFromLocalStorage(surveyId);
            userContext.submissionStatus = "completed";
            setUserContextInLocalStorage(surveyId, userContext);
            return true;
          }}
          onRestart={() => {
            if (!!process.env.REACT_APP_SIGNUP_MODE) {
              capableAuthSDK.user.signOut();
            }

            clearUserContextFromLocalStorage(surveyId);
          }}
          primaryThemeColor={process.env.REACT_APP_PRIMARY_THEME_COLOR}
        />
      </div>
      <footer>
        &copy; {new Date().getFullYear()} {process.env.REACT_APP_COMPANY_NAME}. All rights reserved.
      </footer>
    </>
  );
};

export default Survey;
