import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import capableAuthSDK from "@capable-health/capable-auth-sdk";
import * as Sentry from "@sentry/react";

export default function Scheduling({ schedulingLink }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    Sentry.addBreadcrumb({message: "Getting current patient."});
    const getCurrentPatient = async () => {
      try {
        const accessToken = await capableAuthSDK.user.getAccessToken();
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_PATH}/patients/me`,
          requestOptions
        );
        const patient = await response.json();
        setEmail(patient.email? patient.email : "");
        patient.phone_number && setPhoneNumber(patient.phone_number.number);
        setFirstName(patient.first_name);
        setLastName(patient.last_name);
        setIsLoaded(true);
      } catch(error) {
        Sentry.captureException(error);
        console.error("Unable to get patient");
      }
    };

    getCurrentPatient();
  }, []);

  const buildWidgetQueryString = () => {
    const queryString = `https://app.squarespacescheduling.com/schedule.php?owner=${
      process.env.REACT_APP_ACUITY_OWNER_ID
    }`
    let embedLink = schedulingLink ?? queryString;
    if (firstName) {
      embedLink += `&first_name=${encodeURIComponent(firstName)}`;
    }
    if (lastName) {
      embedLink += `&last_name=${encodeURIComponent(lastName)}`;
    }
    if (email) {
      embedLink += `&email=${encodeURIComponent(email)}`;
    }
    if (phoneNumber) {
      embedLink += `&phone=${encodeURIComponent(phoneNumber)}`;
    }
    return embedLink;
  };

  /* TODO: Add a MUI loading skeleton element */
  return isLoaded ? (
    <Box>
      <iframe
        src={buildWidgetQueryString()}
        title="Schedule Appointment"
        width="100%"
        height="1750"
        frameBorder="0"
      ></iframe>
      <script
        src="https://embed.acuityscheduling.com/js/embed.js"
        type="text/javascript"
      ></script>
    </Box>
  ) : null;
}
